<template>
  <v-dialog
    :value="value"
    @input="$emit('input')"
    width="500">

    <v-card>
      <v-card-title
        class="headline"
        primary-title>
        <!--v-icon color="error">warning</v-icon-->
        <span>{{$t('common.titles.deleteConfirmation')}}</span>

      </v-card-title>

      <v-divider/>

      <v-card-actions>
        <v-btn
          color="error"
          @click="$emit('submit')">
          {{$t('common.buttons.delete')}}
        </v-btn>

        <v-spacer/>

        <v-btn
          color="primary"
          text
          @click="$emit('close')">
          {{$t('common.buttons.cancel')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  export default {
    name: "DeleteDialog",

    props: {
      value: {type: Boolean, default: false},
    },

    data()
    {
      return {
      }
    }
  }
</script>

<style scoped>

</style>